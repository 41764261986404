import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import useAxios from 'axios-hooks';
import { handleNetworkError } from '../../lib/axios';
import { useVideoStatsUpdate } from '../../state/Student/VideoStats';


const DTCVideoSessionSurvey = ({ setEndCard, videoId, videoStats }) => {
    const updateVideoStats = useVideoStatsUpdate();
    const [{ loading: isPosting }, postSurvey] = useAxios({
        url: `/api/video_stats/${videoId}/difficulty_rating/`,
        method: 'PATCH'
    }, { manual: true });

    const onSubmit = (data) => {
        const payload = { difficulty_rating: data };

        postSurvey({ data: payload })
            .then((data) => {
                console.log(data);
                if (data.error) {
                    console.log(data.error);
                } else {
                    // update the local state
                    if( videoStats ) {
                        videoStats.difficulty_rating = data;
                        updateVideoStats(videoStats);
                    }
                    setEndCard(false);
                }
            })
            .catch((error) => {
                handleNetworkError(error);
            });
    };

    // if survey has been answered do not show survey
    if (videoStats?.difficulty_rating !== 0) return null;


    return (
        <>
            <div tw='absolute inset-0 backdrop-blur-3xl pointer-events-none '>
                <div tw='pointer-events-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(28, 0, 87, 0.85)] backdrop-blur-3xl w-full max-w-[350px] md:max-w-[475px] max-h-[425px] rounded-lg'>
                    <div tw='px-4 md:px-9 pt-2 md:pt-5 pb-5 md:pb-10'>
                        <div tw='flex justify-end w-full cursor-pointer' onClick={() => setEndCard(false)}>
                            <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M13.3002 0.685509C13.2077 0.593198 13.0978 0.519961 12.9768 0.469992C12.8559 0.420022 12.7262 0.394301 12.5952 0.394301C12.4643 0.394301 12.3346 0.420022 12.2136 0.469992C12.0926 0.519961 11.9827 0.593198 11.8902 0.685509L7.00022 5.54487L2.11022 0.675551C2.01764 0.583361 1.90773 0.510231 1.78677 0.460338C1.6658 0.410445 1.53615 0.384766 1.40522 0.384766C1.27429 0.384766 1.14464 0.410445 1.02368 0.460338C0.902716 0.510231 0.792805 0.583361 0.700223 0.675551C0.607642 0.767742 0.534201 0.877188 0.484097 0.99764C0.433992 1.11809 0.408203 1.24719 0.408203 1.37757C0.408203 1.50795 0.433992 1.63705 0.484097 1.7575C0.534202 1.87795 0.607642 1.9874 0.700223 2.07959L5.59022 6.94891L0.700223 11.8182C0.607642 11.9104 0.534201 12.0199 0.484097 12.1403C0.433992 12.2608 0.408203 12.3899 0.408203 12.5203C0.408203 12.6506 0.433992 12.7797 0.484097 12.9002C0.534201 13.0206 0.607642 13.1301 0.700223 13.2223C0.792805 13.3145 0.902716 13.3876 1.02368 13.4375C1.14464 13.4874 1.27429 13.5131 1.40522 13.5131C1.53615 13.5131 1.6658 13.4874 1.78677 13.4375C1.90773 13.3876 2.01764 13.3145 2.11022 13.2223L7.00022 8.35295L11.8902 13.2223C11.9828 13.3145 12.0927 13.3876 12.2137 13.4375C12.3346 13.4874 12.4643 13.5131 12.5952 13.5131C12.7262 13.5131 12.8558 13.4874 12.9768 13.4375C13.0977 13.3876 13.2076 13.3145 13.3002 13.2223C13.3928 13.1301 13.4662 13.0206 13.5163 12.9002C13.5665 12.7797 13.5922 12.6506 13.5922 12.5203C13.5922 12.3899 13.5665 12.2608 13.5163 12.1403C13.4662 12.0199 13.3928 11.9104 13.3002 11.8182L8.41022 6.94891L13.3002 2.07959C13.6802 1.7012 13.6802 1.0639 13.3002 0.685509Z' fill='#6E8CA6' />
                            </svg>
                        </div>
                        <div
                            tw='bg-white rounded-xl text-[#1C0057] text-center text-xs font-medium max-w-[133px] py-1 px-1.5 hidden md:block '>
                            A QUICK QUESTION
                        </div>
                        <div tw='text-white text-sm md:text-base lg:text-xl font-medium md:font-semibold mt-2 md:mt-4 text-center md:text-left'>
                            How's the level of math in this video for you?
                        </div>

                        <div tw='w-full flex justify-center'>
                            <div
                                tw='mt-3 text-xs md:text-base rounded-lg border-[1px] border-solid border-white  py-2 md:py-2.5 md:pl-6 text-white cursor-pointer hover:bg-[#46A6F8] w-full  max-w-[180px] md:max-w-[100%] text-center md:text-left'
                                onClick={() => onSubmit(1)}>
                                Easy 
                            </div>
                        </div>
                        <div tw='w-full flex justify-center'>
                            <div
                                tw='mt-3 text-xs md:text-base rounded-lg border-[1px] border-solid border-white  py-2 md:py-2.5 md:pl-6 text-white cursor-pointer hover:bg-[#46A6F8] w-full  max-w-[180px] md:max-w-[100%] text-center md:text-left'
                                onClick={() => onSubmit(2)}>
                                Just right 
                            </div>
                        </div>
                        <div tw='w-full flex justify-center'>
                            <div
                                tw='mt-3 text-xs md:text-base rounded-lg border-[1px] border-solid border-white  py-2 md:py-2.5 md:pl-6 text-white cursor-pointer hover:bg-[#46A6F8] w-full  max-w-[180px] md:max-w-[100%] text-center md:text-left'
                                onClick={() => onSubmit(3)}>
                                Advanced 
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    );
};

DTCVideoSessionSurvey.propTypes = {
    anyProp: PropTypes.any
};

export default DTCVideoSessionSurvey;

