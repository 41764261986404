import React, { useState, useEffect, useRef, useMemo } from 'react';
import tw from 'twin.macro';
import { useSearch } from 'context/SearchProvider';
import { useVideos } from 'state/CatalogState';
import { useVideoStats, useVideoWithStats, useVideosWithStats } from 'state/Student/VideoStats';
import VideoCard from 'components/VideoCard/VideoCard';
import useOnClickOutside from 'hooks/userOnClickOutside';
import { StandardVideoGrid } from '../../../../styles';

const SearchResultsContainer = (props) => {
    const ref = useRef();
    const { results, query, search, dropdown, setDropDown } = useSearch();
    const videos = useVideosWithStats();
    // useOnClickOutside(ref, () => setDropDown(false));

    // console.log('🚀 ~ file: SearchResultsContainer.js:11 ~ SearchResultsContainer ~ videos:', videos);

    let overlay = null;
    if (dropdown) {
        overlay = (
            <>
                <div tw='fixed bg-[#4D3095] text-white pt-6 px-8 left-[270px] w-[400px] pb-8 rounded-lg top-[58px] z-60 hidden lg:block cursor-pointer' ref={ref}>
                    {results?.map((result, i) => {
                        // find the video
                        const video = videos.find(v => v.lesson_id === result.objectID);
                        if( !video )
                            return null;
                        return (<div key={i} onClick={() => { search(video.title); setDropDown(false); }} tw='hover:text-[#FEEA50] py-2'>{video.title}</div>);
                    })}
                    <div onClick={() => { search('All Videos'); setDropDown(false); }} tw='flex items-center justify-start mt-2'>
                        <div>
                            <svg
                                width='16' height='17' viewBox='0 0 16 17' fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <rect
                                    x='2.75' y='3.25' width='10.5' height='10.5'
                                    rx='1.25' stroke='white' strokeWidth='1.5' />
                                <path d='M10.0635 8.04944C10.4412 8.23112 10.4412 8.76888 10.0635 8.95056L7.21678 10.3202C6.88483 10.4799 6.5 10.238 6.5 9.86964L6.5 7.13036C6.5 6.76199 6.88483 6.52009 7.21678 6.6798L10.0635 8.04944Z' fill='white' />
                            </svg>
                        </div>
                        <div tw='ml-1 hover:text-[#FEEA50]'>SEE ALL VIDEOS</div>
                    </div>
                </div>
                <div tw='fixed bg-[#3e257a] text-white pl-6 left-[0px] w-full h-full pb-8 rounded-lg top-[58px] z-60 md:hidden cursor-pointer overflow-y-auto'>
                    <div tw='relative bg-[#4D3095] pt-5 px-5 rounded pb-2 w-[92%] max-h-[90vh] overflow-y-auto'>

                        {results?.map((result, i) => {
                            return (
                                <div key={i} onClick={() => { search(result.title); setDropDown(false); }} tw='hover:text-[#FEEA50] flex items-center pb-2'>
                                    <img src={videos.find(v => v?.lesson_id === result?.objectID)?.url_thumbnail} alt={result.title} tw='w-[2000] h-16 mr-4 rounded mb-3' />
                                    <div tw='font-normal leading-snug font-Poppins text-sm'>{result.title}</div>
                                </div>
                            );
                        })}
                        <div onClick={() => { search('All Videos'); setDropDown(false); }} tw='flex items-center justify-start mt-2'>
                            <div>
                                <svg
                                    width='16' height='17' viewBox='0 0 16 17' fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <rect
                                        x='2.75' y='3.25' width='10.5' height='10.5'
                                        rx='1.25' stroke='white' strokeWidth='1.5' />
                                    <path d='M10.0635 8.04944C10.4412 8.23112 10.4412 8.76888 10.0635 8.95056L7.21678 10.3202C6.88483 10.4799 6.5 10.238 6.5 9.86964L6.5 7.13036C6.5 6.76199 6.88483 6.52009 7.21678 6.6798L10.0635 8.04944Z' fill='white' />
                                </svg>
                            </div>
                            <div tw='ml-1 hover:text-[#FEEA50]'>SEE ALL VIDEOS</div>
                        </div>

                    </div>
                </div>
            </>
        );
    }

    const filteredVideos = useMemo(() => {
        // get a list of videos in order of search results
        if (results?.length > 0 && videos?.length > 0) {
            return results.map(r => {
                return videos.find(v => v.lesson_id === r.objectID);
            }).filter(v => v) ?? [];
        }
        return [];
    }, [results, videos]);

    // const filterSearchVideos = (videos, search) => {
    //     // get a list of videos in order of search results
    //     if (search?.length > 0) {
    //         return search.map( r => {
    //             return videos.find(v => v.lesson_id === r.objectID);
    //         }).filter(v => v);
    //     }
    //     return [];
    // };
    // const filteredVideos = filterSearchVideos(videos, results);
    // const sortedVideos = filteredVideos.sort((a, b) => a.title.localeCompare(b.title));

    const sortedAllVideos = videos.sort((a, b) => a.title.localeCompare(b.title));
    if (query && query.length > 0) {
        if (filteredVideos.length > 0) {
            return (
                <>
                    <div tw='relative min-h-screen w-full md:block hidden'>
                        {/*{props.children}*/}
                        {overlay}
                        <div tw='absolute pt-20 bg-[#1C0057] z-40 w-full min-h-screen pl-14'>
                            <div tw='2xl:[max-width:var(--layout-width)] mx-auto'>
                                <div tw='text-[#9D91BB] text-[20px] font-light leading-snug pb-1 pt-8'>Search results for</div>
                                <div tw='text-[32px] font-semibold pb-12 text-[#FFF]'>“{query}”</div>
                                <StandardVideoGrid tw='pr-28'>
                                    {filteredVideos.map((video, i) => {
                                        return (<VideoCard key={i} video={video} showTitle={true} />);
                                    })}
                                </StandardVideoGrid>
                            </div>
                        </div>
                    </div>

                    <div tw='md:hidden'>
                        {/*{props.children}*/}
                        {overlay}
                        <div tw='absolute pt-28 bg-[#1C0057] z-40 w-full min-h-screen pl-4'>
                            <StandardVideoGrid tw='pr-4'>
                                {filteredVideos.map((video, i) => {
                                    return (<VideoCard key={i} video={video} showTitle={true} />);
                                })}
                            </StandardVideoGrid>
                        </div>
                    </div>
                </>
            );
        }
        if (query === 'All Videos') {
            return (
                <><div tw='hidden md:block relative min-h-screen w-full'>
                    {overlay}
                    <div tw='absolute pt-24 pl-28 bg-[#1C0057] z-40 w-full text-white'>
                        <div tw='2xl:[max-width:var(--layout-width)] mx-auto'>
                            <div tw='pb-9'>All Videos</div>
                            <StandardVideoGrid tw='pr-28'>
                                {sortedAllVideos.map((video, i) => {
                                    return (<VideoCard key={i} video={video} showTitle={true} />);
                                })}
                            </StandardVideoGrid>
                        </div>
                    </div>
                </div>

                <div tw='relative md:hidden min-h-screen w-full'>
                    {overlay}
                    <div tw='absolute pt-24 pl-10 bg-[#1C0057] z-40 w-full text-white'>
                        <div tw='pb-5'>All Videos</div>
                        <StandardVideoGrid tw='pr-10'>
                            {sortedAllVideos.map((video, i) => {
                                return (<VideoCard key={i} video={video} showTitle={true} />);
                            })}
                        </StandardVideoGrid>
                    </div>
                </div></>
            );
        }
        else {
            return (
                <div tw=' relative min-h-screen w-full'>
                    {props.children}
                    {overlay}
                    <div tw='absolute pt-24 md:pt-40 pl-5 md:pl-28 bg-[#1C0057] z-40 w-full text-white'>
                        <div tw='2xl:[max-width:var(--layout-width)] mx-auto'>
                            <div tw='text-[32px] font-bold pb-4 text-center w-3/4 pl-16 md:w-auto md:pl-0 md:text-left'>No videos matched your search...</div>
                            <div tw='pb-5 text-xl text-[#9D91BB] text-center w-3/4 pl-16 md:w-auto md:pl-0 md:text-left'>Here are some videos you might like.</div>

                            <div tw='pb-5 hidden md:block'>Videos You Might Like</div>
                            <StandardVideoGrid tw='pr-6 md:pr-28'>
                                {videos.map((video, i) => {
                                    return (<VideoCard key={i} video={video} showTitle={true} />);
                                })}
                            </StandardVideoGrid>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div tw=' relative h-full w-full'>
                {props.children}
            </div>
        );
    }
};

export default SearchResultsContainer;
