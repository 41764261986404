import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import Modal from 'components/Modal/Modal';
import BackgroundImage from 'lib/components/BackgroundImage';
import LinesBackground from '../GettingStartedSlider/imgs/LinesBackground.png';
import WaveBackground from '../GettingStartedSlider/imgs/WaveBackground.png';
import { useNavigate } from 'react-router-dom';
import SvgWhiteXButton from 'images/icons/WhiteXButton';
import { useVideo } from 'state/CatalogState';
import LoadingOverlay from '../LoadingOverlay';

const CardContainer = tw.div`cursor-pointer bg-contain bg-no-repeat rounded-lg relative overflow-hidden`;

const NewVideoDialog = ({ videoId, setOnboardState }) => {
    const video = useVideo(videoId)
    console.log("🚀 ~ NewVideoDialog ~ video:", video)
    console.log("🚀 ~ NewVideoDialog ~ video:", video?.id)
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const url_thumbnail = video?.url_thumbnail;
    
    useEffect(() => {
        if (video) setLoading(false);
    }, [video])


    const handleClick = () => {
        setLoading(true);
        setOnboardState?.({ new_video_id: null });
        navigate(`/video/${video.id}`);
    };

    const onClose = useCallback(() => {
        setLoading(true);
        setOnboardState?.({ new_video_id: null });
    }, [setOnboardState]);

    return (
        <Modal isOpen={isOpen} modalChildrenStyle={tw`w-full max-w-[390px] md:max-w-[650px] mx-auto px-5`}>
            <div tw='rounded-lg relative' css={[css` background: linear-gradient(266deg, #C050FC 8.05%, #6B57DC 86.07%);`]}>
                <LoadingOverlay show={loading} fixed={false} />
                <BackgroundImage image={LinesBackground} tw='mix-blend-soft-light' />
                <BackgroundImage image={WaveBackground} tw='mix-blend-soft-light top-10' />
                <div tw='text-3xl text-white text-center pt-7 pb-5'>NEW VIDEO!</div>
                <div tw='mx-auto max-w-[300px] md:max-w-[430px]'>
                    <CardContainer
                        tw='flex w-full min-h-[170px] md:min-h-[260px]'
                        css={[
                            css`
                        background-image: url(${url_thumbnail});
                        background-position: center;
                        background-size: cover;
                    `,
                            tw`hover:[box-shadow:0 0 20px 20px #ffffff22] active:[box-shadow:0 0 20px 20px #ffffff44]`
                        ]}
                        onClick={handleClick}
                    >

                        <div tw='hidden md:flex justify-center items-center w-full' className='play-button'>
                            <svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="62.9402" cy="62.9402" r="58.8152" stroke="white" stroke-width="8.25" />
                                <path d="M98.797 59.1297C101.73 60.8233 101.73 65.0572 98.797 66.7508L48.3119 95.8983C45.3785 97.5919 41.7119 95.475 41.7119 92.0878L41.7119 33.7927C41.7119 30.4056 45.3785 28.2886 48.3119 29.9822L98.797 59.1297Z" fill="white" />
                            </svg>
                        </div>
                    </CardContainer>
                </div>

                <div tw='text-2xl text-white text-center pt-3 pb-12'>{video?.title}</div>
                <button
                    onClick={onClose}
                    tw='bg-white rounded-full cursor-pointer absolute -right-4 -top-6 w-12 h-12 flex justify-center items-center '
                >
                    <SvgWhiteXButton tw='w-4 h-4' />
                </button>
            </div>
        </Modal>
    );
};

NewVideoDialog.propTypes = {
    anyProp: PropTypes.any,
}

export default NewVideoDialog;

